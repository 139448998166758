<template>
    <div class="c-app flex-row  bg-white">
        <CContainer fluid class="mt-5">
            <CRow class="mt-sm-5 py-sm-5 mt-md-5 py-md-5 mt-lg-5 py-lg-5">
                <div id="language">
                    <api-changer v-if="developerMode()"></api-changer>
                    <locale-changer variant="outline-orange"/>
                </div>
                <div id="logo">
                    <img id="logo-img" alt="Logo Fachhochschule Dortmund" src="img/FHDO-Logo-large.svg" height="65">
                </div>
            </CRow>
            <CRow class="justify-content-center">
                <CCol sm="1" md="1" lg="1" class="mt-5 mb-5">  
                </CCol>
                <CCol sm="4" md="6" lg="6">
                     <h2 class="mb-n1">{{ $t("pages.login.heading") }}</h2>
                     <small class="text-muted">{{ $t('pages.login.subTitle') }} | <span style="color: #ff5100;">{{ $t('generally.fbInformatik') }}</span></small>
                    <p class="mt-4">
                        {{ $t('pages.login.appInfoText') }}
                    </p>
                    <p>
                        {{ $t('pages.login.footerText') }} <a href="#" @click.prevent="" v-b-modal.userAgreementModal>{{ $t('pages.login.footerLink') }}</a><span v-html="$t('pages.login.footerText2')"></span>
                    </p>
                </CCol>
                <CCol sm="6" md="4" lg="4">
                   
                    <CCard class="">
                        <b-overlay :show="loading" id="overlay-background" variant="white" opacity="0.85" blur="2px">
                            <CCardBody>
                                <validation-observer ref="observer" v-slot="{ handleSubmit }">
                                    <CForm @submit.stop.prevent="handleSubmit(submit)">
                                        <h5>{{ $t("pages.login.header") }}</h5>
                                        <p class="mt-n1"><small class="text-muted">{{ $t("pages.login.subHeader") }}</small></p>
                                        <validation-provider
                                            :name="$t('pages.login.fields.username.label')"
                                            :rules="{ required: true }"
                                            v-slot="validationContext"
                                        >
                                            <b-form-group class="pt-2">
                                                <b-input-group>
                                                    <template #prepend>
                                                        <b-input-group-text>
                                                            <CIcon name="cil-user"/>
                                                        </b-input-group-text>
                                                    </template>
                                                    <b-form-input
                                                        type="text"
                                                        id="login-username"
                                                        name="login-username"
                                                        ref="loginUsername"
                                                        :disabled="disable"
                                                        v-model="form.username"
                                                        :state="getValidationState(validationContext)"
                                                        :placeholder="$t('pages.login.fields.username.placeholder')"
                                                        class="underlined"
                                                        aria-describedby="login-username-feedback"
                                                    ></b-form-input>
                                                    <!--<b-form-invalid-feedback id="login-username-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>-->
                                                </b-input-group>
                                            </b-form-group>
                                        </validation-provider>
                                        <validation-provider
                                            :name="$t('pages.login.fields.password.label')"
                                            :rules="{ required: true, min: 3 }"
                                            v-slot="validationContext"
                                        >
                                            <b-form-group class="pt-1 pb-4">
                                                <b-input-group>
                                                    <template #prepend>
                                                        <b-input-group-text>
                                                            <CIcon name="cil-lock-locked"/>
                                                        </b-input-group-text>
                                                    </template>
                                                    <b-form-input
                                                        type="password"
                                                        id="login-password"
                                                        name="login-password"
                                                        ref="login-password"
                                                        :disabled="disable"
                                                        v-model="form.password"
                                                        :placeholder="$t('pages.login.fields.password.placeholder')"
                                                        class="underlined"
                                                        :state="getValidationState(validationContext)"
                                                        aria-describedby="password-feedback"
                                                    ></b-form-input>
                                                    <!--<b-form-invalid-feedback id="password-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>-->
                                                </b-input-group>
                                            </b-form-group>
                                        </validation-provider>
                                        <div class="form-group d-flex justify-content-between">
                                            <label for="remember" class="d-flex align-content-center">
                                                <b-form-checkbox switch id="remember" v-model="remember">&nbsp;{{ $t("pages.login.fields.remember") }}</b-form-checkbox>
                                            </label>
                                        </div>
                                        <CRow>
                                            <CCol col="12" class="text-left">
                                                <CButton type="submit" :disabled="disable" color="orange" class="px-4 btn-block" style="width: 160px">{{ $t('buttons.login') }}</CButton>
                                                <div v-show="disable" class="pt-3">
                                                    <span  class="text-danger">{{ $t('pages.login.loginFailed') }}</span><br/>
                                                    <span  class="text-danger">{{ $t('pages.login.waitForLogin', {sec: seconds}) }}</span>
                                                </div>
                                            </CCol>
                                        </CRow>
                                    </CForm>
                                </validation-observer>
                            </CCardBody>
                        </b-overlay>
                    </CCard>
                    
                </CCol>
                <CCol sm="1" md="1" lg="1">  
                </CCol>
            </CRow>

        </CContainer>
        <b-modal
            id="userAgreementModal"
            ref="userAgreementModal"
            orange
            :title="$t('generally.userAgreement.heading')"
            @ok="this.$refs['userAgreementModal'].hide()"
            size="xl"
            ok-only
            footer-class="justify-content-end"
            ok-variant="orange"
            :ok-title="$t('buttons.ok')"
        >
            <span v-html="$t('generally.userAgreement.message')"></span>
        </b-modal>
    </div>
</template>

<script>
import userService from "@/services/userService";
import LocaleChanger from "../../components/locale-changer";
import ApiChanger from "../../components/apiChanger";

export default {
    name: 'Login',
    components: {
        LocaleChanger,
        ApiChanger
    },
    data: () => ({
        form: {
            username: '',
            password: ''
        },
        remember: false,
        loading: false,
        disable: false,
        seconds: 0
    }),
    mounted() {
        document.getElementById('login-username').focus();
    },
    methods: {
        getValidationState({dirty, validated, valid = null}) {
            return dirty || validated ? valid : null;
        },
        submit() {
            if (!this.loading && !this.disable) {
                this.loading = true;
                userService.login(this);
            }
        },
        developerMode(){
            return !!localStorage.getItem("developer");
        },
    }
};
</script>

<style>
#logo {
    position: absolute;
    top: 48px;
    left: 34px;
}
#language {
    position: absolute;
    top: 15px;
    right: 15px;
}
</style>
